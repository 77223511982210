// Cards / Accordion
//
// card
//
.card-img-top {
    overflow: hidden;
    picture,
    img {
        width: 100%;
        height: auto;
    }
}
.card-body {
    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}

//
// Accordion
//
.accordion-item {
    margin-top: 0.25rem;
    border-bottom: $card-border-width solid $card-border-color !important;
}

/*.accordion-item + .accordion-item {
    margin-top: 0.25rem;
}*/

.accordion-header {
    padding: 0;
    .btn-link {
        position: relative;
        width: 100%;
        padding: $card-spacer-y calc(#{$card-spacer-x} + 2em) $card-spacer-y $card-spacer-x;
        text-align: left;
        color: $primary;
        &:hover,
        &:focus {
            color: darken($primary, 6%);
            text-decoration: none;
        }

        &:focus {
            outline: 0;
            box-shadow: inset 0 0 0 0.16rem rgba($primary, 0.2);
        }
    }

    .accordion-header-icon {
        position: absolute;
        height: 0.8em;
        width: 0.8em;
        top: 50%;
        right: $card-spacer-x;
        transform: translate(0, -50%);
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            height: 1px;
            width: 100%;
            background-color: currentColor;
            transition: all 0.2s ease-in-out;
        }
        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }

    .btn-link.collapsed {
        .accordion-header-icon {
            &:before {
                transform: rotate(0);
            }
            &:after {
                transform: rotate(90deg);
            }
        }
    }
}

.accordion-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: -($card-spacer-x / 2);
    margin-right: -($card-spacer-x / 2);
}

.accordion-content-item {
    flex-grow: 1;
    order: 1;
    padding-left: ($card-spacer-x / 2);
    padding-right: ($card-spacer-x / 2);
    width: 100%;
}

.accordion-content-text {
    *:last-child {
        margin-bottom: 0;
    }
}

.accordion-body {
    border-top: $card-border-width solid $card-border-color;
}

// - Position
.accordion-content-right,
.accordion-content-left {
    .accordion-content-item {
        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }
}

.accordion-content-below,
.accordion-content-right {
    .accordion-content-gallery {
        margin-top: $card-spacer-x;
    }
}

.accordion-content-right {
    .accordion-content-gallery {
        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}

//
// Card Group Element
//
.card-group-element {
    margin: -$card-deck-margin;
    margin-bottom: -$card-deck-margin !important;
    display: flex;
    flex-wrap: wrap;
}

.card-group-element-item {
    width: 100%;
    padding: $card-deck-margin*2 $card-deck-margin;

    .card {
        height: 100%;
        border: 0;
        overflow: hidden;
    }

    .card-img-top {
        margin-bottom: 1.125rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 2.625rem;
        }
    }

    .card-body {
        padding: 0;
    }

    .card-title {
        margin-bottom: $card-spacer-y;
    }

    .card-subtitle {
        margin-bottom: $card-spacer-y;
    }

    .card-title + .card-subtitle {
        margin-top: -0.5rem;
    }

    .card-btn {
        margin-top: $card-spacer-y;
    }
}

.frame-background-none,
.frame-background-light,
.frame-background-hatched {
    .card-group-element {
        .card-title {
            color: $primary;
        }

        .card-body::before {
            color: $primary;
        }
    }
}

.card-group-element-columns-2 {
    .card-group-element-item {
        @include media-breakpoint-up(sm) {
            width: calc(99.9% / 2);
        }
    }
}

.card-group-element-columns-3 {
    .card-group-element-item {
        @include media-breakpoint-up(md) {
            width: calc(99.9% / 3);
        }
    }
}

.card-group-element-columns-4 {
    .card-group-element-item {
        @include media-breakpoint-up(sm) {
            width: calc(100% / 2);
        }
        @include media-breakpoint-up(lg) {
            width: calc(100% / 4);
        }
    }
}
