// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
$white: #fff;
$gray-100: #f5f5f5;
$gray-200: #f6f6f6;
$gray-300: #e9e9e9;
$gray-400: #ced4da;
$gray-700: #7c7c7c;
$gray-900: #2b2b2b;
$black: #000;


// Color system
$primary: #914c12;
$primary-dark: #824005;
$secondary: $gray-900;
$light: $gray-100;
$dark: $gray-900;

$body-color: $dark;

$link-color: $primary;
$link-hover-color: $primary-dark;
$link-alternate-color: $white;
$link-alternate-hover-color: darken($link-alternate-color, 15%);

$bullet-color: $primary;

$selection-bg: #bd9471;
$selection-color: $white;

// Paragraphs
$paragraph-margin-bottom: 0.5rem;

// Options
$enable-caret: false;
$enable-validation-icons: false;

$spacer: 1rem;
$spacers: (
    5: ($spacer * 2),
    6: ($spacer * 3)
);

// Grid breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1420px
);

// Grid containers
$container-max-widths: (
        xl: 1406px
);

// Grid columns
$grid-gutter-width: 26px;

// Components
$border-width: 1px;
$border-color: $gray-700;
$border-radius: 0;

$navbar-width: 90px;
$container-max-widths-xl: map_get($container-max-widths,xl);

// Frame Spacing
$frame-spacing: 1rem;
$frame-spacing-scaling: (
        xs: 4.5,
        lg: 6,
        xl: 7.5
);

// Frame Background
$frame-backgrounds: (
    primary: (
        background: $primary,
        link-color: $link-alternate-color,
        link-hover-color: $link-alternate-hover-color
    ),
    light: (
        background: $light,
        link-color: $link-color,
        link-hover-color: $link-hover-color
    ),
    dark: (
        background: $dark,
        link-color: $link-alternate-color,
        link-hover-color: $link-alternate-hover-color
    ),
    hatched: (
        background: $light,
        link-color: $link-color,
        link-hover-color: $link-hover-color
    )
);

// Typography
$font-family-dmseriftext: 'DM Serif Text', sans-serif;
$font-family-geologica: 'Geologica', sans-serif;

$font-family-sans-serif: $font-family-geologica;
$font-family-serif: $font-family-dmseriftext;

$font-size-base: 0.9375rem;
$font-size-base-xl: 1.0625rem;

$font-weight-light: 300;
$font-weight-normal: 300;
$font-weight-bold: 700;

$font-weight-base: $font-weight-light;

$heading-color-secondary: $primary;

$h1-font-size: 2.25rem;
$h1-font-size-xl: 4.375rem;
$h2-font-size: 1.75rem;
$h2-font-size-xl: 3.125rem;
$h3-font-size: 1.625rem;
$h4-font-size: $h3-font-size;
$h5-font-size: 1.25rem;
$h6-font-size: $font-size-base;

$big-title-font-size: 3.125rem;
$small-title-font-size: 1.375rem;

$headings-margin-bottom: $spacer;
$headings-font-family: $font-family-dmseriftext;
$headings-font-weight: $font-weight-normal;
$headings-line-height: 1.12;

// Navs

$nav-link-padding-y: 1rem;
$nav-link-padding-x:  1.1rem;

$nav-link-padding-y-lg: 0.5rem;

// Dropdowns
$dropdown-padding-y: 0;

// Pagination
$pagination-disabled-color: $gray-700;

// Buttons + Forms
$btn-padding-y: 0.625rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x: 0.625rem;
$btn-padding-x-lg: 0.75rem;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 0.9375rem;
$btn-font-size-lg: 1.25rem;
$btn-line-height: 1;
$btn-font-weight: $font-weight-bold;
$btn-border-width: $border-width*2;
$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

$btn-sm-font-size: 0.938rem;
$btn-sm-padding-y: 0.625rem;
$btn-sm-padding-x: 0.5rem;

$input-color: $dark;
$input-border-color: $gray-700;

// Cards
$card-title-size: 1.2rem;
$card-subtitle-size: 0.8rem;
$card-spacer-y: 1rem;
$card-spacer-x: 1rem;
$card-border-radius: 0;
$card-inner-border-radius: 0;
$card-border-color: transparent;
$card-bg: transparent;

// Figures
$figure-caption-color: $gray-700;

// CTA
$cta-headline-font-size: $h2-font-size;
$cta-headline-font-size-xl: $h2-font-size-xl;

// Misc
$zindex-menu: 1080;
$zindex-skiplinks: 1090;
